.App {
	margin-top: 15px;
}

.App .navbar-brand {
	font-weight: bold;
	padding: 5px 5px;
}

.LoaderButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
}
@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}

/* HOME PAGE */

.lander {
	padding: 80px 0;
	text-align: center;
}

.lander h1 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
}

.lander p {
	color: #999;
}

/* ME PAGE */

#toast {
	border-radius: 5px;
	padding: 20px;
	margin: 15px 0;
	font-weight: bold;
	font-size: 1.3em;
	color: white;
	text-align: center;
}

.loadingMessage {
	font-size: 1.2em;
	color: #666666;
	text-align: center;
}

.invitedEmail {
	background-color: #fafafa;
	padding: 5px;
}

.smallSpacer {
	padding: 1vh 0;
}

.spacer {
	padding: 2vh 0;
}

.bg-success {
	background-color: #5cb85c;	
}

.bg-failure {
	background-color: #d9534f;	
}

.testHistoryContainer {
	border-style: solid;
	border-color: #e5e5e5;
	border-width: 1px 0 0 1px;
	border-radius: 8px;
	box-shadow: 2px 2px 3px 1px #e5e5e5;
	padding: 15px 5px;
	margin: 10px;
	box-sizing: border-box;
}

.testHistoryLabel {
	color: #555555;
	padding-top: 1px;
	font-weight: bold;
	padding: 0 8px;
}

.testHistoryData {
	margin-top: 1px;
}

.btnCol {
	box-sizing: border-box;
	padding: 10px 30px;
}

.buttonStyle {
	padding: 25px 10px;
	margin: 0;
}

.mb-1 {
	margin-bottom: 1vh;
}

.mt-1 {
	margin-top: 2vh;
}

.memberListLabel {
	font-weight: bold;
	font-size: 0.9em;
	color: #666666;
}

.memberCard {
	border-width: 0 0 1px;
	border-style: solid;
	border-color: #888888;
	margin: 10px;
	padding: 10px;
	background-color: #fefefe;
}

.sectionHeading {
	margin-top: 5vh;
}

/* LOGIN PAGE */

@media all and (min-width: 480px) {
	.Login {
		padding: 60px 0;
	}

	.Login form {
		margin: 0 auto;
		max-width: 320px;
	}
}

.passwordFieldSubtext {
	font-size: 1.1em;
	text-align: center;
	color: #666666;
}

.passwordFieldSubtext:hover { color: #000000; }


/* RESET PASSWORD PAGE */
@media all and (min-width: 480px) {
	.ResetPassword {
		padding: 60px 0;
	}

	.ResetPassword form {
		margin: 0 auto;
		max-width: 320px;
	}

	.ResetPassword .success {
		max-width: 400px;
	}
}

.ResetPassword .success {
	margin: 0 auto;
	text-align: center;
}

.ResetPassword .success .glyphicon {
	color: grey;
	font-size: 30px;
	margin-bottom: 30px;
}
/* SIGN UP PAGE */

@media all and (min-width: 480px) {
	.Signup {
		padding: 60px 0;
	}

	.Signup form {
		margin: 0 auto;
		max-width: 320px;
	}
}

.Signup form span.help-block {
	font-size: 14px;
	padding-bottom: 10px;
	color: #999;
}

/* NOT FOUND PAGE */

.NotFound {
	padding-top: 100px;
	text-align: center;
}

/* QUIZ PAGE */

/*.container {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    color: white;
    text-align: center;
}*/

.progress-header {
	color: #999;
}

.question h1,
.question h2 {
    margin: 15px;
}

.question-image {
	background-color: #fff;
	border-color: 1px solid #CCC;
	padding: 15px;
	max-width: 100%;
	overflow: auto;
}

.answer {
    color: #666;
    border: 1px solid #fff;
    padding: 5px 10px 5px 45px;
    width: 100%;
    text-align: left;
    margin-top: 10px;
    position: relative;
    font-size: 22px;
    cursor: pointer;
    transition: ease-in-out, width 0.35s ease-in-out;
}

.answer.selected {
    color: #4f4c4c;
    background-color: #c8ffbb;
}

.answer:hover {
    color: #4f4c4c;
    background-color: #c8ffbb;
}

.answer:focus {
    outline: none;
}

.results span.correct {
    color: #c8ffbb;
}

.results span.failed {
    color: #f27c7c;
}

.error {
    color: #f27c7c;
}

.letter {
    color: #4f4c4c;
    width: 30px;
    position: absolute;
    left: 0;
    text-align: center;
    height: 28px;
    top: 0;
    padding: 5px;
    text-transform: uppercase;
}

.submit-button {
    margin-bottom: 2rem;
}

/*.btn {
    color: #4f4c4c;
    background-color: #f4f4f4;
    padding: 10px 50px;
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 20px;
    cursor: pointer;
}*/

.btn-primary {
    color: #4f4c4c;
    background-color: #ffc107;
    font-weight: bold;
}

.btn-secondary {
    color: #B0D2EC;
    background-color: #0F63A4;
    border-color: #053C66;
    font-weight: bold;
}
.btn-secondary:hover {
    color: #fff;
    background-color: #084C81;
    border-color: #053C66;
}